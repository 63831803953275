import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import BlogContentSkeleton from "./blogContentSkeleton";
import { formatISODateToReadableString } from "../../utils/util-function";
type Props = {};

const BlogContent = (props: Props) => {
  const { id } = useParams();
  const [document, setDocument] = useState<any>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchDocument = async () => {
      try {
        // Reference to the document with the specific ID
        const docRef = doc(db, "articles", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setDocument(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchDocument();
  }, [id]);

  return (
    <div className="flex flex-col justify-center items-center mt-6">
      {loading && <BlogContentSkeleton />}
      {!loading && Object.keys(document).length > 0 && (
        <div className="w-[100vw] md:w-[70vw]">
          <div className="p-4">
            <h1 className="text-5xl font-semibold">{document?.title}</h1>
            <p className="text-sm opacity-70 font-light mt-2">
              {formatISODateToReadableString(document?.createdAt)}
            </p>
            <p className="text-sm opacity-70 font-light mt-2">
              {document?.totalToReadArticle} min read
            </p>
            <p className="text-base font-light mt-2">
              {parse(document?.content)}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogContent;
