import React from "react";
import Skeleton from "react-loading-skeleton";
import { colors } from "../utils/colors";

type Props = {};

const BlogContentSkeleton = (props: Props) => {
  const isDark = localStorage.getItem("darkMode") === "true";
  const skeletonColor = colors[isDark ? "dark" : "light"];
  return (
    <Skeleton
      baseColor={skeletonColor.primary}
      highlightColor={skeletonColor.secondary}
      className="w-[100vw] md:w-[70vw] h-[50vh] md:h-[50vh] "
    />
  );
};

export default BlogContentSkeleton;
