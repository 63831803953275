export const colors = {
  dark: {
    primary: "#1f1f1f",
    secondary: "#f8f8f8",
  },
  light: {
    primary: "#f8f8f8",
    secondary: "#1f1f1f",
  },
};
