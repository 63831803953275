import React, { useEffect, useState } from "react";
import Style from "./Navbar.module.scss";
import Toggler from "./home/Toggler";
import { Link, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { info } from "../info/Info";
import { Navigation } from "../types/navigation.ts";
import GradientText from "./ui/gradient-text";
const links = [
  {
    name: "blog",
    to: Navigation.blog,
    active: "blog",
  },
];

export default function Navbar({ darkMode, handleClick }) {
  const location = useLocation();
  const [active, setActive] = useState(
    location.pathname === "/"
      ? "home"
      : location.pathname.slice(1, location.pathname.length)
  );

  useEffect(() => {
    links.forEach((link) => {
      if (link.to === location.pathname) {
        setActive(link.active);
      }
    }); // activates the links automatically when the user goes to path without clicking on the link
  }, [location.pathname]);
  return (
    <div className="pt-10">
      <header className="text-gray-600 body-font">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <p
            className={`flex title-font font-medium items-center  mb-4 md:mb-0 ${
              darkMode ? "text-white" : "text-gray-900"
            }`}
          >
            <Link to={"/"} onClick={() => setActive("home")}>
              {active === "home" ? (
                <span className="ml-3 text-xl">
                  Muaaz <span className="font-bold">K.</span>
                </span>
              ) : (
                <>
                  <span className="ml-3 text-xl opacity-60">Muaaz </span>
                  <span className="font-bold opacity-100 text-xl">K.</span>
                </>
              )}
            </Link>
          </p>
          <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center">
            {links.map((link, index) => {
              return (
                <Box
                  key={index}
                  alignSelf={"self-start"}
                  className={
                    link.active === active && !link.type && Style.active
                  }
                  sx={{ borderImageSource: info.gradient }}
                  marginX={{ xs: "0.8rem", md: "0" }}
                  marginRight={{
                    sm: "1rem",
                    md: "2rem",
                    lg: "2.5rem",
                    xl: "3rem",
                  }}
                >
                  <Link
                    to={link.to}
                    onClick={() => setActive(link.active)}
                    className={Style.link}
                  >
                    {!link.type && (
                      <p style={{ padding: "0.5rem 0" }}>{link.name}</p>
                    )}
                    {link.type && <h1>{link.name}</h1>}
                  </Link>
                </Box>
              );
            })}
            <Box
              alignSelf={"self-start"}
              marginX={{ xs: "0.8rem", md: "0" }}
              marginRight={{
                sm: "1rem",
                md: "2rem",
                lg: "2.5rem",
                xl: "3rem",
              }}
            >
              <a
                href="https://firebasestorage.googleapis.com/v0/b/blogs-da3af.appspot.com/o/resume%2FMuaaz-Khan.pdf?alt=media&token=20523950-d970-46ca-84fb-4abf161da7e7"
                target="_blank"
                rel="noreferrer"
              >
                <div
                  style={{ padding: "0.5rem 0" }}
                  className="flex flex-row items-center"
                >
                  Resume
                </div>
              </a>
            </Box>
          </nav>

          <Toggler darkMode={darkMode} handleClick={handleClick} />
        </div>
      </header>
    </div>
  );
}
