export const colorWithOpacity = (hex: string, opacity: number): string => {
  if (
    typeof hex !== "string" ||
    !/^#([A-Fa-f0-9]{3}){1,2}($|[A-Fa-f0-9]{2})$/.test(hex)
  )
    throw new Error("Invalid hexadecimal color value");
  if (typeof opacity !== "number" || opacity > 1 || opacity < 0)
    throw new Error("Opacity should be float between 0 - 1");
  let color = hex.substring(1);
  if (color.length === 5 || color.length === 8)
    color = color.substring(0, color.length - 2);
  if (color.length === 3)
    color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
  color += Math.round(opacity * 255)
    .toString(16)
    .padStart(2, "0");
  return `#${color}`.toUpperCase();
};

export function formatISODateToReadableString(isoDate) {
  // Utility function to get the ordinal suffix for the day of the month
  function getOrdinalSuffix(day) {
    if (day >= 11 && day <= 13) return "th"; // Special cases for 11th, 12th, and 13th
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  // Parse the ISO 8601 date string into a Date object
  const date = new Date(isoDate);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    throw new Error("Invalid date");
  }

  // Extract the day, month, and year
  const day = date.getDate();
  const month = new Intl.DateTimeFormat("en", { month: "long" }).format(date);
  const year = date.getFullYear();

  // Construct the formatted string
  return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
}
