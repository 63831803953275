import React from "react";
import { colors } from "../utils/colors";
import Skeleton from "react-loading-skeleton";

type Props = {};

const BlogCardSkeleton = (props: Props) => {
  const isDark = localStorage.getItem("darkMode") === "true";
  const skeletonColor = colors[isDark ? "dark" : "light"];
  return (
    <Skeleton
      className="md:w-[50vw] w-[70vw] h-24 rounded-md"
      baseColor={skeletonColor.primary}
      highlightColor={skeletonColor.secondary}
    />
  );
};

export default BlogCardSkeleton;
