import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { colors } from "../utils/colors";

const BlogCardSkeleton = ({}) => {
  const isDark = localStorage.getItem("darkMode") === "true";
  let skeletonColors = colors[isDark ? "dark" : "light"];
  return (
    <div className="w-[30rem] my-1 ">
      <div className="px-10 py-3">
        <Skeleton
          baseColor={skeletonColors.primary}
          highlightColor={skeletonColors.secondary}
          height={120}
          style={{
            borderRadius: "4px",
          }}
        />
      </div>
    </div>
  );
};

export default BlogCardSkeleton;
