// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDs3-CP3VVZlUwWvzq8Eq4dVL6aY0_hhlA",
  authDomain: "blogs-da3af.firebaseapp.com",
  projectId: "blogs-da3af",
  storageBucket: "blogs-da3af.appspot.com",
  messagingSenderId: "1090704664545",
  appId: "1:1090704664545:web:8de5c94bd5fb075bfe14a0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get Firestore instance
const db = getFirestore(app);

export { db };
