import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
//@ts-ignore
import Style from "../BaseLayout.module.scss";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../firebase";
import BlogCardSkeleton from "./blog-card-skeleton";
import { formatISODateToReadableString } from "../../utils/util-function";
type Props = {};

function BlogCards({
  title,
  description,
  totalToReadArticle,
  typeOfContent,
  articleId,
  createdAt,
  handleClickToBlog,
  isDark,
}) {
  return (
    <div
      onClick={() => {
        handleClickToBlog(articleId);
      }}
      className="md:w-[50vw] w-[70vw]   my-2 hover:cursor-pointer transition-all duration-200 hover:opacity-70  ease-in hover:-translate-y-1"
    >
      <div className="px-10 py-3 fade-in ">
        <div className="flex justify-between items-center">
          <div className="font-semibold text-lg mb-1">{title}</div>
          <div
            className="px-1 py-0.5"
            style={{
              backgroundColor: isDark ? "#fff" : "#000",
              color: isDark ? "#000" : "#fff",
              borderRadius: "0.25rem",
            }}
          >
            <p className="font-normal text-sm">{typeOfContent}</p>
          </div>
        </div>

        <p className=" text-base font-light">{description}</p>
        <p className=" opacity-50 mt-1 text-sm font-light">
          {formatISODateToReadableString(createdAt)} - {totalToReadArticle} min
          read
        </p>
      </div>
    </div>
  );
}

const Blog = ({ darkMode }) => {
  const isDark = localStorage.getItem("darkMode") === "true";
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchDocuments = async () => {
      setLoading(true);
      try {
        const q = query(
          collection(db, "articleCard"),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);
        const docs = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setDocuments(docs);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, []);

  const handleCardClick = (id) => {
    console.log(id);
    navigate(`/blog/${id}`);
  };
  return (
    <Box>
      <div className="w-[100%] md:w-[50%]  mx-auto px-4 md:px-0">
        <h1 className="text-6xl font-bold mt-4">Blog</h1>
        <div className="mt-5 flex flex-col  justify-center items-center md:items-start">
          {/* <BlogCards isDark={isDark} /> */}
          {loading && <BlogCardSkeleton />}
          {!loading &&
            documents.length > 0 &&
            documents.map((doc, index) => {
              return (
                <BlogCards
                  key={index}
                  handleClickToBlog={handleCardClick}
                  {...doc}
                  isDark={isDark}
                />
              );
            })}
        </div>
      </div>
    </Box>
  );
};

export default Blog;
