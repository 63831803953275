import React, { useEffect, useState } from "react";
import Style from "./Home.module.scss";

import { Box } from "@mui/material";

import { linkImages } from "../../img";
import BlogCard from "./blogCard";
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import { db } from "../firebase";
import BlogCardSkeleton from "./blogCardSkeleton";
import { useNavigate } from "react-router-dom";
export default function Home() {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchDocuments = async () => {
      setLoading(true);
      try {
        const q = query(
          collection(db, "articleCard"),
          orderBy("createdAt", "desc"),
          limit(3)
        );
        const querySnapshot = await getDocs(q);
        const docs = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setDocuments(docs);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, []);

  const handleCardClick = (id) => {
    console.log(id);
    navigate(`/blog/${id}`);
  };

  return (
    <>
      <Box
        component={"main"}
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent={"center"}
        paddingX={{ xs: 5, md: 0 }}
        // alignItems={"center"}
        // minHeight={"calc(100vh - 175px)"}
      >
        <Box
          marginLeft={{ xs: 0, md: 10 }}
          //   className={classNames(Style.text, "text-center")}
          position={"relative"}
          width={{ xs: "100%", md: "40%" }}
          textAlign={{ xs: "center", md: "left" }}
        >
          <img
            src={linkImages["pf2Image"]}
            alt="profile"
            className={
              "h-40 hidden md:block w-auto rounded-lg absolute top-10 -left-48"
            }
          />
          <p className="font-semibold md:text-4xl text-2xl">Muaaz Khan</p>
          <p className="md:text-xl text-lg  mt-2 font-normal">hi 👋</p>
          <p className="md:text-xl text-lg  mt-4 font-normal">
            I’m a Engineer and Entrepreneur from Pakistan.
          </p>
          <p className="md:text-lg text-base mt-4 font-thin">
            I'm a Team Lead at{" "}
            <span
              className="
            font-semibold
            underline
          "
            >
              {" "}
              <a
                href="https://www.linkedin.com/company/the-scripterz"
                target="_blank"
              >
                The Scripterz
              </a>
            </span>{" "}
            , where I turn the latest tech trends into real-world solutions. I
            lead teams to push boundaries and craft innovative software that
            stands out.
          </p>

          <p className="md:text-lg text-base mt-4 font-thin">
            Always looking for new opportunities and challenges. I love to
            learn.
          </p>
          <p className="md:text-lg text-base mt-4 font-thin">
            I write about self-improvement, philosophy, tech, business or
            anything that interests me {":)"}
          </p>

          <p className="md:text-lg text-base mt-4 font-thin">
            Best way to reach me is through{" "}
            <span className="font-semibold underline">
              {" "}
              <a target="_blank" href="mailto:muaazkhan1575@gmail.com">
                muaazkhan1575@gmail.com
              </a>
            </span>
          </p>
        </Box>
      </Box>
      <Box component={"main"} className="container md:mx-auto mx-3   mt-8">
        <p className="text-3xl font-semibold underline underline-offset-4 ">
          Ideas I’ve Shared
        </p>
        {loading && <BlogCardSkeleton />}
        {!loading &&
          documents.length > 0 &&
          documents.map((doc) => (
            <BlogCard
              key={doc.id}
              {...doc}
              handleClickToBlog={handleCardClick}
            />
          ))}
      </Box>
    </>
  );
}
