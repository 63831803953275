import React from "react";



function GradientText({ children }: { children: React.ReactNode }) {
  return (
    <div className="bg-gradient-to-r from-primary  to-secondary inline-block text-transparent bg-clip-text">{children}</div>
  );
}

export default GradientText;
